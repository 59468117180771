import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';

const Archive = ({
    pageContext: {description, from, issues, keywords, lang, title, url},
}) => (
    <Layout
        description={description}
        keywords={keywords}
        lang={lang}
        name='archive'
        path={from}
        title={title}
        url={url}
    >
        <h2>{dictionary.archive[lang]}</h2>
        <ul>
            {issues.map(({
                frontmatter: {issue, title, year},
                fields: {slug},
            }) => (
                <li key={slug}>
                    <Link to={`/${lang}/issues/${issue}`}>
                        {`${dictionary.issue[lang]} ${issue} (${year}): ${title}`}
                    </Link>
                </li>
            ))}
        </ul>
    </Layout>
);

export default Archive;
